import React, { useEffect, useMemo, useState } from "react";
import { Paper } from "@mui/material";
import { debounce } from "lodash-es";
const ImageContainer = ({ children }) => {
  const [imageData, setImageData] = useState(null);
  const [fetched, setFetched] = useState(false);
  const [screenResolution, setScreenResolution] = useState({
    width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0,
    height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0,
  });
  useEffect(() => {
    const handleResize = debounce(() => {
      setScreenResolution({
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0,
      });
    }, 300);
    window.addEventListener('resize', handleResize);
    return () => {
      handleResize.cancel(); // Cancel any pending debounced function calls
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const debouncedScreenResolution = useMemo(() => screenResolution, [screenResolution]);
  
  useEffect(() => {
    const fetchRandomImage = () => {
      if (!fetched) {
        const { width, height } = debouncedScreenResolution;
        //const url = `https://placekitten.com/${width}/${height}`
        const url = `https://picsum.photos/${width}/${height}`
        fetch(url)
          .then(response => {
            setImageData(response.url);
          })
          .catch(() => {
            fetchFallbackImage();
          })
          .finally(() => {
            setFetched(true);
          });
      }
    };
    const fetchFallbackImage  = () => {
      const { width, height } = debouncedScreenResolution;
      fetch(`https://source.unsplash.com/random/${width}x${height}` )
        .then(response => {
          setImageData(response.url);
        })
        .catch(error => {
          console.error('Error fetching fallback image:', error);
          setImageData(''); // Set an empty string if fallback image fetch also fails
        });
    };
    fetchRandomImage();
  }, [fetched, debouncedScreenResolution]);
  const backgroundImageStyle = { backgroundImage: imageData ? `url(${imageData})` : '', backgroundSize: 'cover', backgroundPosition: 'center',
    width: screenResolution.width,
    height: screenResolution.height,
    margin:0, padding:0
  };
  return (
    <div style={backgroundImageStyle}>
      <Paper style={{ margin:0, padding:0, width: screenResolution.width, height: screenResolution.height }}>
        {children}
      </Paper>
    </div>
  );
};
export default ImageContainer;
