//// @ts-nocheck
import { useState, forwardRef, useCallback, useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { useSnackbar, SnackbarContent, CustomContentProps } from "notistack";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { toCapitalizeFromUnderScore } from "../../lib/helpers/helpers";
import { useSettings } from "../LexicalWrapper/playground/context/SettingsContext";


const useStyles = makeStyles(() => ({
  root: {
    "@media (min-width:600px)": {
      minWidth: "344px !important"
    }
  },
  card: {
    width: "100%"
  },
  typography: {
    color: "#000"
  },
  actionRoot: {
    padding: "8px 8px 8px 16px",
    justifyContent: "space-between"
  },
  icons: {
    marginLeft: "auto"
  },
  expand: {
    padding: "8px 8px",
    transform: "rotate(0deg)",
    color: "#000",
    transition: "all .2s"
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  paper: {
    backgroundColor: "#fff",
    padding: 16
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4
  },
  button: {
    padding: 0,
    textTransform: "none"
  }
}));
// @ts-ignore
const AISummarizer = forwardRef<HTMLDivElement>(({ id, content, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(false);
    const { analyzed, stats, colorMap } = useSettings();
    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
    }, []);
    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

  return (
    <>
      <>
        <CardActions classes={{ root: classes.actionRoot }} style={{marginTop:20}}>
          <List>
            <ListItem>
              <Typography variant="h5" className={classes.typography} style={{clear:'both'}}>
                Feedback
              </Typography>
            </ListItem>

            {Object.entries(stats).map(([key, value], i) => (
              <ListItem key={i} dense>
                <ListItemText primary={`${toCapitalizeFromUnderScore(key || '')}:`} secondary={value} />
              </ListItem>
            ))}

            {analyzed && Boolean(analyzed.length) && (analyzed as [any]).reduce((acc: [any], cur) => {
              if (!acc.find(item => item.ruleId === cur.ruleId)) {
                acc.push(cur);
              }
              return acc;
            }, []).map((item, index) => (
              <ListItem key={index} style={{backgroundColor:colorMap[item.source]}}>
                {/*@ts-ignore*/}
                <ListItemText primary={item?.reason}/>
              </ListItem>
            ))}
          </List>
          <div className={classes.icons} style={{display:'none'}}>
            <IconButton
              aria-label="Show more"
              size="small"
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded
              })}
              onClick={handleExpandClick}
            >
              <ExpandMoreIcon />
            </IconButton>
            <IconButton
              size="small"
              className={classes.expand}
              onClick={handleDismiss}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper className={classes.paper}>
            <Typography
              gutterBottom
              variant="caption"
              style={{ color: "#000", display: "block" }}
            >
              PDF ready
            </Typography>
            <Button size="small" color="primary" className={classes.button}>
              <CheckCircleIcon className={classes.checkIcon} />
              Download now
            </Button>
          </Paper>
        </Collapse>
      </>
    </>
    );
  }
);

AISummarizer.displayName = "AISummarizer";

export default AISummarizer;
