// ./index.ts
// @ts-nocheck
import React, {useCallback, useContext, useMemo, useState} from 'react';
import ReactDOM from 'react-dom';
import {QueryClient, QueryClientProvider} from "react-query";
import {SnackbarProvider} from "notistack";
import {OrganizationContext, OrganizationContextProvider, UserContextProvider, ThemeContext, WebsocketContextProvider} from "./lib/contexts";
import { ThemeProvider } from "@mui/material";
import ThemeV5 from "./lib/themes/Theme_v5";
import AIWriter from "./components/AIWriter";
import AISummarizer from "./components/AISummarizer";
const queryClient = new QueryClient();
declare module 'notistack' {
   interface VariantOverrides {
      summary: true
   }
}
const Wrapper = ({theme, children, originalProps}) => (
   <QueryClientProvider client={queryClient}>
      <SnackbarProvider
         anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
         variant={'info'}
         autoHideDuration={4000}
         preventDuplicate={true}
         maxSnack={10}
         Components={{
            summary: AISummarizer,
         }}
      >
         <OrganizationContextProvider organizationID={originalProps?.apiKey}>
            <ThemeContainer theme={theme} colors={originalProps?.colors}>
               <UserContextProvider>
               <WebsocketContextProvider props={originalProps}>
                  {children}
               </WebsocketContextProvider>
               </UserContextProvider>
            </ThemeContainer>
         </OrganizationContextProvider>
      </SnackbarProvider>
   </QueryClientProvider>
);
const ThemeContainer = ({ theme = 'auto', colors, children }) => {
   const { state: { primaryColor, secondaryColor } } = useContext(OrganizationContext);
   const chosenPrimaryColor = useMemo(()=>colors && colors[0] ? colors[0] : primaryColor,[colors, primaryColor])
   const chosenSecondaryColor = useMemo(()=>colors && colors[1] ? colors[1] : secondaryColor,[colors, secondaryColor])
   const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
   const [darkMode, setDarkMode] = useState(() => {
      return theme === 'dark' || (theme === 'auto' && (localStorage.getItem('SGPT_THEME') === 'true' || prefersDarkMode));
   });
   const toggleTheme = useCallback(() => {
      setDarkMode(!darkMode);
      localStorage.setItem('SGPT_THEME', !darkMode);
   }, [darkMode]);
   const chosenTheme = useMemo(
      () => ThemeV5(chosenPrimaryColor, chosenSecondaryColor, darkMode),
      [darkMode, chosenPrimaryColor, chosenSecondaryColor],
   );
   return (
         <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
               <ThemeProvider theme={chosenTheme}>
                  {children}
               </ThemeProvider>
         </ThemeContext.Provider>
   );
};
const components = {
   ai_writer: AIWriter
};
const ContextWriter = (props, embed = 'ai_writer', root = 'body') => {
   const target = root === 'body' ? document.body : document.getElementById(root);
   const Component = components[embed] || AIWriter;
   //console.log(`Rendering SupportGPT: ${embed} component`, props, embed, root)
   const container = document.createElement('div');
   container.className = 'context_writer_embed';
   container.id = 'context_writer_embed';
   target.appendChild(container);
   const resetStyles = {
      '*': {
         margin: 0,
         padding: 0,
         boxSizing: 'border-box',
         fontFamily: 'inherit',
         fontSize: 'inherit',
         lineHeight: 'inherit',
         fontWeight: 'inherit',
         color: 'inherit',
         textDecoration: 'none',
         listStyle: 'none',
         backgroundColor: 'transparent',
         border: 'none',
         outline: 'none',
      },
   };
   ReactDOM.render(<Wrapper theme={props.theme} originalProps={props}><Component {...props} /></Wrapper>, container);
};
window.ContextWriter = ContextWriter;
window.ContextWriter({theme:'light', colors: ['#E1E4EA99']});
export default ContextWriter;
