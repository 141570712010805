import {
  $applyNodeReplacement,
  $createTextNode,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode
} from "lexical";
import { $createEmojiNode, SerializedEmojiNode } from "./EmojiNode";

interface SerializedHighlightedTextNode extends SerializedTextNode {
  color: string;
  className: string;
}

export class HighlightedTextNode extends TextNode {
  static readonly type = 'HighlightedTextNode';
  static color: string;
  static className: string;

  constructor(textContent: string, private color: string, private className: string, key?: NodeKey) {
    super(textContent, key);
    this.className = className;
  }

  static getColor(): string {
    return this.color;
  }
  getClassName(): string {
    const self = this.getLatest();
    return self.__className;
  }

  static create(textContent: string, color: string, className: string, key: NodeKey): HighlightedTextNode {
    return new HighlightedTextNode(textContent, color, className, key);
  }

  static getType(): string {
    return HighlightedTextNode.type;
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = document.createElement('span');
    const inner = super.createDOM(config);
    dom.style.backgroundColor = this.color;
    dom.className = 'highlighted-text-inner';
    inner.className = this.className;
    dom.appendChild(inner);
    return dom;
  }

  updateDOM(prevNode: TextNode, dom: HTMLElement, config: EditorConfig): boolean {
    const inner = dom.firstChild;
    if (inner === null) {
      return true;
    }
    if (prevNode instanceof HighlightedTextNode && prevNode.color !== this.color) {
      dom.style.color = this.color;
    }
    super.updateDOM(prevNode, inner as HTMLElement, config);
    return false;
  }



  static importJSON(serializedNode: SerializedHighlightedTextNode): TextNode {
    const node = $createTextNode(serializedNode.text);
    node.className = serializedNode.className
    return node;
  }
  exportJSON(): SerializedHighlightedTextNode {
    return {
      ...super.exportJSON(),
      color: this.color,
      className: this.getClassName() || '',
      type: 'HighlightedTextNode',
    };
  }

}

export function $isHighlightedTextNode(
  node: LexicalNode | null | undefined
): node is HighlightedTextNode {
  return node instanceof HighlightedTextNode;
}

export function $createHighlightedTextNode(
  textContent: string,
  color: string,
  className: string,
  key: NodeKey
): HighlightedTextNode {
  const node = HighlightedTextNode.create(textContent, color, className, key);
  return $applyNodeReplacement(node);
}
