import {$createLinkNode} from '@lexical/link';
import {$createListItemNode, $createListNode} from '@lexical/list';
import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {$createHeadingNode, $createQuoteNode} from '@lexical/rich-text';
import {$createParagraphNode, $createTextNode, $getRoot} from 'lexical';
import * as React from 'react';
import './index.css';
import {SettingsContext, useSettings} from './context/SettingsContext';
import {SharedAutocompleteContext} from './context/SharedAutocompleteContext';
import {SharedHistoryContext} from './context/SharedHistoryContext';
import Editor from './Editor';
import PlaygroundNodes from './nodes/PlaygroundNodes';
import {TableContext} from './plugins/TablePlugin';
import PlaygroundEditorTheme from './themes/PlaygroundEditorTheme';
import { $createMentionNode } from "./nodes/MentionNode";
import ImageContainer from "../../ImageContainer";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";

console.warn(
  'If you are profiling the playground app, please ensure you turn off the debug view. You can disable it by pressing on the settings control in the bottom-left of your screen and toggling the debug view setting.',
);

function prepopulatedRichText() {
  const root = $getRoot();
  if (root.getFirstChild() === null) {
    const intro = $createParagraphNode();
    intro.append($createTextNode('Jot It 2.0 ~ alpha').toggleFormat('code'));
    root.append(intro);
    const heading = $createHeadingNode('h1');
    heading.append($createTextNode('Welcome!'));
    root.append(heading);
    const quote = $createQuoteNode();
    quote.append(
      $createTextNode(
        `Jot It, a once popular app for webOS back in 2010, has now been revived and merged with AI to create a cutting-edge, modern solution.`,
      ),
    );
    root.append(quote);
    const paragraph = $createParagraphNode();
    paragraph.append(
      $createTextNode('The playground is a '),
      $createTextNode('demo').toggleFormat('code'),
      $createTextNode(' environment built with.'),
      $createTextNode(' Try typing in '),
      $createTextNode('some text').toggleFormat('bold'),
      $createTextNode(' with '),
      $createTextNode('different').toggleFormat('italic'),
      $createTextNode(' formats.'),
    );
    root.append(paragraph);
    const paragraph2 = $createParagraphNode();
    paragraph2.append(
      $createTextNode(
        'Make sure to check out the various plugins in the toolbar. You can also use #hashtags or @-mentions too! For instance: ',
      ),
    );
    paragraph2.append(
      $createMentionNode('Austin Cameron')
    )
    paragraph2.append(
      $createTextNode(' of '));
    paragraph2.append(
      $createMentionNode('Formulate Labs')
    )
    root.append(paragraph2);
    const paragraph3 = $createParagraphNode();
    paragraph3.append(
      $createTextNode(`If you'd like to find out more, you can:`),
    );
    root.append(paragraph3);
    const list = $createListNode('bullet');
    list.append(
      $createListItemNode().append(
        $createTextNode(`Visit the `),
        $createLinkNode('https://twitter.com/ustincameron').append(
          $createTextNode('Twitter'),
        ),
        $createTextNode(` for latest news & updates.`),
      ),
      $createListItemNode().append(
        $createTextNode(`Playground code can be found `),
      ),
    );
    root.append(list);
    const paragraph4 = $createParagraphNode();
    paragraph4.append(
      $createTextNode(
        `Lastly, we're constantly adding cool new features to this playground. So make sure you check back here when you next get a chance :).`,
      ),
    );
    root.append(paragraph4);
  }
}

function App(): JSX.Element {
  const {
    content,
    settings: {isCollab, emptyEditor},
  } = useSettings();
/*
|| isCollab
    ? prepopulatedRichText || null
    : emptyEditor
      ? undefined
      : prepopulatedRichText*/
  const initialConfig = {
    editorState: content,
    namespace: 'JOT_IT',
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

/*
  useEffect(()=>{
    enqueueSnackbar('', {
      persist: true,
      variant: 'summary',
    });
    enqueueSnackbar('2 Adverbs, meeting the goal of 2 or fewer.', {
      persist: true,
      variant: 'default',
    });
    enqueueSnackbar('1 use of Passive Voice, meeting the goal of 2 or fewer.', {
      persist: true,
      variant: 'success',
    });
    enqueueSnackbar('1 phrase has a similar alternative.', {
      persist: true,
      variant: 'info',
    });
    enqueueSnackbar('1 of 11 sentences is hard to read.', {
      persist: true,
      variant: 'warning',
    });
    enqueueSnackbar('1 of 11 sentences is very hard to read.', {
      persist: true,
      variant: 'error',
    });
  },[])
*/
  return (
    <LexicalComposer initialConfig={initialConfig}>
      <SharedHistoryContext>
        <TableContext>
          <SharedAutocompleteContext>
            <div> {/* className="editor-shell"*/}
                <Editor />
            </div>
            {/*
            {isDevPlayground ? <Settings /> : null}
            {isDevPlayground ? <DocsPlugin /> : null}
            {isDevPlayground ? <PasteLogPlugin /> : null}
            {isDevPlayground ? <TestRecorderPlugin /> : null}
            {measureTypingPerf ? <TypingPerfPlugin /> : null}*/}
          </SharedAutocompleteContext>
        </TableContext>
      </SharedHistoryContext>
    </LexicalComposer>
  );
}
export default function PlaygroundApp(): JSX.Element {
  return (
    <SettingsContext>
      <ImageContainer>
        <App />
      </ImageContainer>
    </SettingsContext>
  );
}
