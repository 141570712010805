import React from "react";
import { getCurrentLocale } from "../constants/locale";
const currentLocale = getCurrentLocale()

export const ThemeContext = React.createContext(
   {
      darkMode: localStorage.getItem('SGPT_THEME') === 'true' || (!localStorage.getItem('SGPT_THEME') && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches),
      toggleTheme: () => { },
     currentLocale:currentLocale
   }
);
