import { useQuery } from 'react-query';
import {getOrganization} from "../api";

const useOrganization = (organizationId = undefined, subdomain = undefined) => {
  const result = useQuery('organization_'+organizationId+'_'+subdomain, () => getOrganization(organizationId, subdomain), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useOrganization;
