import {createContext, useMemo} from 'react';
import useOrganization from "../hooks/useOrganization";
import {sanitizeURL} from "../helpers/helpers";

export const OrganizationContext = createContext({});
export const OrganizationContextProvider = ({ organizationID, children, props }) => {
  const urlSplit = window.location.host.split('.');
  const {data:organizationObject} = useOrganization(organizationID, urlSplit[0]);
   const correctWebsite = useMemo(() => {
      if(window.location.href.includes('localhost') || window.location.href.includes('fiddle.jshell')){
         return true
      }
      if (organizationObject && organizationObject.website) {
         try {
            const windowLocation = sanitizeURL(window.location.href);
            const orgLocation = sanitizeURL(organizationObject.website);
            return windowLocation.toLowerCase() === orgLocation.toLowerCase();
         } catch (error) {
            console.error(`Invalid URL defined within SGPT settings: https://${organizationObject.website}, found ${window.location.href}`);
         }
      }
      if (organizationObject && !organizationObject.website) {
         console.error(`Invalid URL defined within SGPT settings: https://${organizationObject?.website}, found ${window.location.href}`);
      }
      return false;
   }, [organizationObject, organizationID]);
   //console.error('correctWebsite ',correctWebsite, organizationObject, `SGPT settings: https://${organizationObject?.website}, found ${window.location.href}`);
   
   return (
    <OrganizationContext.Provider
      value={{
        actions: {},
        state: {
           primaryColor: organizationObject?.primary_color || '#333',
           secondaryColor: organizationObject?.secondary_color || '#f4t',
           correctWebsite,
          ...organizationObject,
        },
      }}
      {...props}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
