import { useQuery } from 'react-query';
import {getAI} from "../api/getAI";

const useAI = (mode, prompt, value) => {
  const safeValue = encodeURIComponent(value)
  const result = useQuery('ai_'+mode+'_'+prompt+'_'+safeValue, () => getAI(mode, prompt, safeValue), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useAI;
