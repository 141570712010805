import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import useEmbeddableChatWebsocket from "../hooks/useEmbeddableChatWebsocket";
import useEmbeddableChat from "../hooks/useEmbeddableChat";
import getCurrentUser from "../api/getCurrentUser";
import {uuidv4} from "../helpers/helpers";
import {OrganizationContext} from "./OrganizationContext";
export const WebsocketContext = createContext({});
export const WebsocketContextProvider = ({ children, props }) => {
   const { state: {correctWebsite} } = useContext(OrganizationContext);
   const [chatOpenInternal, setChatOpen] = useState(props.chatOpen);
   const apiKey = useMemo(()=>correctWebsite ? props.apiKey : '',[props, correctWebsite])
   const contactID = useMemo(()=>props.contactID,[props])
   const { userID } = getCurrentUser();
   const visitorID = useMemo(() => {
      if (!contactID && !userID) {
         // no contact or user, so assume visitor
         if (localStorage.getItem('SGPT')) {
            return localStorage.getItem('SGPT');
         } else {
            return localStorage.setItem('SGPT', uuidv4());
         }
      } else {
         return null;
      }
   }, [contactID, userID]);
   const { sendChat, getChat: getChatRaw, isOnline, ipAddress, isTyping, messages, setMessages, setIsUnread, isUnread, toggleMute, isMute } = useEmbeddableChatWebsocket(apiKey, contactID, visitorID, chatOpenInternal);
   const getChat = useCallback(getChatRaw, [apiKey, contactID, visitorID, isMute]);
   useEffect(() => {
      if(isOnline){
         getChat()
            .then(data => {
               //console.log('WebsocketContextProvider useEmbeddableChatWebsocket Chat:', data)
               setMessages(data)
            })
            .catch(error => {
             //  console.error('WebsocketContextProvider useEmbeddableChatWebsocket Error:', error)
            });
      }
   }, [getChat, isOnline]);
   const {
      isLoading,
      data: convo,
      refetch,
   } = useEmbeddableChat(apiKey, contactID, visitorID, isOnline, ipAddress);
   const isLoadingConvo = isLoading && !isOnline;
   const refetchConvo = useMemo(()=>isOnline ? getChat : refetch,[isOnline, getChat, refetch])
   const chosenConvo = useMemo(()=>isOnline ? messages : convo,[isOnline, messages, convo])
   const latestConvo = useMemo(() => {
      
      const uniqueIds = new Set();
      const combinedMessages = Array.isArray(chosenConvo) ? chosenConvo : [];
      const combinedConvo = Array.isArray(convo) ? convo : [];
      const mergedArray = [...combinedConvo, ...combinedMessages];
      if (mergedArray.length === 0) {
         return [];
      }
      return mergedArray.reduce((result, item) => {
         if (!uniqueIds.has(item.id)) {
            uniqueIds.add(item.id);
            result.push(item);
         }
         return result;
      }, []);
   }, [chosenConvo, convo]);
   if (window.location.search.includes('debug=true')) {
      console.log('isLoadingConvo: ', latestConvo?.length, isLoadingConvo)
      console.log('visitorID: ', visitorID)
      console.log('isOpen: ', chatOpenInternal)
      console.log('isTyping: ', isTyping)
      console.log('isUnread: ', isUnread)
      console.log('isOnline: ', isOnline)
   }
  return (
    <WebsocketContext.Provider
      value={{
        actions: {
           getChat,
           sendChat,
           refetchConvo,
           setIsUnread,
           setChatOpen,
           toggleMute
        },
        state: {
           isMute,
           isLoadingConvo,
           latestConvo,
           visitorID,
           contactID,
           isOnline,
           ipAddress,
           isTyping,
           isUnread,
           chatOpenInternal,
        },
      }}
      {...props}
    >
      {children}
    </WebsocketContext.Provider>
  );
};
