const getCurrentUser = () => {
  const userObject = JSON.parse(localStorage.getItem('CW_AUTH')) || JSON.parse(sessionStorage.getItem('CW_AUTH')) || undefined;
  
  return {
    token: userObject ? userObject.token : undefined,
    userID: userObject ? userObject.user_id : undefined,
    user: userObject ? userObject.user : undefined,
    suggestedOrganization: userObject ? userObject.suggested_organization : undefined,
    organization: userObject ? userObject.organization : undefined,
    organizationID: userObject && userObject.user ? userObject.user.organization_id : undefined,
    //firstName: userObject ? userObject.user.first_name : undefined,
  }
  /*
  const result = useQuery('current_user', () => postUserAuth(requestParams), {
    refetchOnWindowFocus: false,
    refetchInterval: 50000,
  });
  return result.data;*/
};

export default getCurrentUser;
