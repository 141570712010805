import axios from 'axios';
import useUser from '../hooks/useUser';
import { API_HOST } from '../constants/constants';
export const getAI = async (mode, prompt, value) => {
  if(!mode || !prompt || !value){
    return false
  }
  
  const apiURL = API_HOST + '/ai?type='+String(mode)+'&promptType='+String(prompt)+'&prompt='+value;
  console.log('apiURL')
  console.log(apiURL)
  const { token, userID } = ''; //useUser();
  try {
    const { data } = await axios.get(apiURL,{
      headers: {
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
