import {alpha, createTheme, darken, lighten } from "@mui/material";
import {common, brandingPalette, fontList} from './theme_constants'
import {opacity} from "./utils";
const ThemeV5 = (primaryColor = '#fd7b70', secondaryColor = '#9b062f', darkMode = false) => createTheme({
   mode: darkMode ? 'dark' : 'light',
   palette: {
      common: common,
      branding: brandingPalette,
      primary: {
         main: primaryColor,
         light: lighten(primaryColor,0.5),
         dark: darken(primaryColor,0.5),
         text: darkMode ? common.off_white : common.black,
         contrastText: darkMode ? common.off_white : common.black,
         background: alpha(primaryColor, 0.92),
         backgroundHover: alpha(primaryColor, 0.5),
         border: primaryColor,
         bodyText: darkMode ? common.off_white : common.black,
         paperText: darkMode ? common.off_white : common.black,
      },
      secondary: {
         main: secondaryColor,
         light: lighten(secondaryColor,0.5),
         dark: darken(secondaryColor,0.5),
         text: darkMode ? common.off_white : common.black,
         contrastText: darkMode ? common.off_white : common.black,
         background: alpha(secondaryColor, 0.92),
         backgroundHover: alpha(secondaryColor, 0.5),
         border: secondaryColor,
         bodyText: darkMode ? common.off_white : common.black,
         paperText: darkMode ? common.off_white : common.black,
      },
      backgrounds: {
         paper: darkMode ? opacity(common.off_black_2, 0.8) : alpha(common.white, 0.4),
         paperOpaque: alpha(darkMode ? common.dark_grey : common.white, 1),
         header: common.black,
         footer: darkMode ? common.off_black : common.white,
         background: darkMode ? common.off_black : common.white,
      },
      error: {
         main: common.error,
         dark: darken(common.error, 0.5),
         light: lighten(common.error, 0.5),
         contrastText: common.white,
         background: lighten(common.error, 0.92),
         backgroundHover: lighten(common.error, 0.5),
         border: darken(common.error, 0.5),
      },
      warning: {
         main: common.warning,
         dark: darken(common.warning, 0.5),
         light: lighten(common.warning, 0.5),
         contrastText: common.white,
         background: lighten(common.warning, 0.92),
         backgroundHover: lighten(common.warning, 0.5),
         border: darken(common.warning, 0.5),
      },
      success: {
         main: common.success,
         dark: darken(common.success, 0.5),
         light: lighten(common.success, 0.5),
         contrastText: common.white,
         background: lighten(common.success, 0.92),
         backgroundHover: lighten(common.success, 0.5),
         border: darken(common.success, 0.5),
      },
      info: {
         main: common.info,
         dark: darken(common.info, 0.5),
         light: lighten(common.info, 0.5),
         contrastText: common.white,
         background: lighten(common.info, 0.92),
         backgroundHover: lighten(common.info, 0.5),
         border: darken(common.info, 0.5),
      },
      action: {
         main: darkMode ? common.off_white : common.black,
      },
   },
   typography: {
      fontFamily: fontList,
      fontSize: 14,
      button: {
         textTransform: 'none',
      },
      body1: {
         color: darkMode ? common.off_white : common.black,
      },
      body2: {
         color: darkMode ? common.off_white : common.black,
      },
      subtitle1: {
         color: darkMode ? common.off_white : common.black,
      },
      subtitle2: {
         color: darkMode ? common.off_white : common.black,
      },
      caption: {
         color: darkMode ? common.off_white : common.black,
         fontSize: 12,
      },
   },
   shape: {
      borderRadius: 8,
   },
   components: {
      MuiSvgIcon: {
         styleOverrides: {
            root: {
               color: darkMode ? common.off_white : common.black,
            },
         },
      },
      MuiLink: {
         styleOverrides: {
            root: {
               cursor: 'pointer',
               color: `${darkMode ? common.off_white : common.black} !important`,
               textDecoration: 'none',
               '&:hover': {
                  textDecoration: 'underline',
               },
            },
         },
      },
      MuiDivider: {
         styleOverrides: {
            root: {
               backgroundColor: darkMode ? common.off_white : common.medium_grey,
            },
         },
      },
      MuiDialogContentText: {
         styleOverrides: {
            root: {
               color: darkMode ? common.off_white : common.black,
            },
         },
      },
      MuiPaper: {
         styleOverrides: {
            root: {
               backgroundColor: darkMode ? opacity('#0F1418', 0.8) : opacity(common.white, 0.75), // Make the background semi-transparent
               backdropFilter: 'blur(5px)',
               boxShadow: darkMode ? '0 8px 32px 0 rgba(31, 38, 135, 0.37 )' : `0 0 16px 0 ${alpha(common.white, 0.5)} !important`,
               border: '1px solid rgba( 255, 255, 255, 0.18 )',
               borderRadius: '8px',
   
               '& .MuiStepper-root': {
                  backgroundColor: 'transparent',
                  backdropFilter: 'inherit',
                  boxShadow: 'inherit !important',
               },
               '&& .MuiPaper-root': {
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderRadius:8,
                  borderColor: darkMode ? common.white : common.transparent,
                  backgroundColor: darkMode ? 'transparent' : opacity(common.white, 1),
                  backdropFilter: 'inherit',
                  boxShadow: 'inherit !important',
               },
               '& .MuiTextField-root': {
                  borderWidth: 0,
                  borderStyle: 'solid',
                  borderRadius: 'inherit',
                  borderColor: darkMode ? common.transparent : common.medium_grey,
                  backgroundColor: 'transparent',
                  backdropFilter: 'inherit',
                  boxShadow: 'inherit !important',
                  '&::placeholder': {
                     color: darkMode ? common.transparent : common.medium_grey,
                  },
               },
            },
         },
      },
      MuiAutocomplete: {
         styleOverrides: {
            root: {
               '& .MuiInputBase-root .MuiAutocomplete-input': {
                  color: darkMode ? common.off_white : common.black,
               },
               '& .MuiAutocomplete-clearIndicator': {
                  color: darkMode ? common.off_white : common.black,
               },
               '& .MuiAutocomplete-popupIndicator': {
                  color: darkMode ? common.off_white : common.black,
               },
            },
         },
      },
      MuiInputBase: {
         styleOverrides: {
            input: {
               color: darkMode ? common.off_white : common.grey['900'],
               '&::placeholder': {
                  color: darkMode ? common.off_white : common.grey['900'],
               },
               '&:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${common.white} inset`,
                  WebkitTextFillColor: primaryColor,
                  caretColor: primaryColor,
               },
            },
         },
      },
   }
});
export default ThemeV5
