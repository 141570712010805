import * as React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect } from "react";
import { TextNode } from "lexical";
import { useSettings } from "../context/SettingsContext";
import { originalColorMap } from "../../../../lib/helpers/helpers";
function keywordColorTransformLastWorking(node) {
  const currentStyle = node.getStyle();
  const chosenColor = originalColorMap['retext-profanities']
  const chosenProperty = `background-color`
  console.log('chosenColor',chosenColor)
  if (node.getTextContent() === 'fuck') { // we want to change this to compare against analyzed
    if (!currentStyle.includes(`${chosenProperty}: ${chosenColor}`)) {
      console.log('setting currentStyle', currentStyle)
      node.setStyle(`${chosenProperty}: ${chosenColor}`);
    }
  }else{
    if (currentStyle.includes(`${chosenProperty}: ${chosenColor}`)) {
      console.log('removing currentStyle', currentStyle)
      node.setStyle(`${chosenProperty}: inherit`);
    }
  }
}
function keywordColorTransform(analyzed) {
  return function(node) {
    const currentText = node.getTextContent();
    console.log('analyzed', analyzed)
    console.log('currentText', currentText)
    const currentStyle = node.getStyle();
    const chosenError = analyzed.find(error => currentText.includes(error.actual));
    //const chosenError = analyzed.find(error => currentText.includes(error.actual));
    const chosenErrorText = chosenError ? chosenError.actual : '';
    const chosenErrorSource = chosenError ? chosenError.source : 'none';
    const chosenColor = originalColorMap[chosenErrorSource];


    const chosenProperty = `background-color`;
    console.log('currentText', currentText);
    console.log('chosenErrorText', chosenErrorText);
    console.log('currentText.includes ', currentText.includes(chosenErrorText));
    console.log('chosenErrorText.includes ', chosenErrorText.includes(currentText));

    console.log('chosenErrorSource', chosenErrorSource);
    console.log('chosenColor', chosenColor);

    if (currentText.includes(chosenErrorText)) { // We now compare against analyzed
      if (!currentStyle.includes(`${chosenProperty}: ${chosenColor}`)) {
        console.log('setting currentStyle', currentStyle);
        node.setStyle(`${chosenProperty}: ${chosenColor}`);
      }
    } else {
      if (currentStyle.includes(`${chosenProperty}: ${chosenColor}`)) {
        console.log('removing currentStyle', currentStyle);
        node.setStyle(`${chosenProperty}: inherit`);
      }
    }
  }
}
export default function AnalyzeNodesPlugin() {
  const [editor] = useLexicalComposerContext();
  const { analyzed } = useSettings();
  useEffect(() => {
    const unsubscribe = editor.registerNodeTransform(TextNode, keywordColorTransform(analyzed));
    return () => {
      unsubscribe(); // Unregister the transformer on component unmount
    };
  }, [editor, analyzed]);
  return null; // Render nothing
}
