export const LOCALES = [
   {
      "localizations": "French",
      "locale":"fr",
      "subscription_group_display_name": "Abonnement de crédit IA",
      "app_name": "Ecrivain de contexte IA",
      "editor": "Editeur",
      "suggestions": "Suggestions",
      "grammar_check": "Vérification de la grammaire",
      "context": "Contexte",
      "pro_tip": "Astuce Pro : Pour obtenir les meilleurs résultats, utilisez le formatage Markdown lors de l'écriture."
   },
   {
      "localizations": "French (Canada)",
      "locale":"fr-CA",
      "subscription_group_display_name": "Abonnement de crédit IA",
      "app_name": "Écrivain de contexte IA",
      "editor": "Éditeur",
      "suggestions": "Suggestions",
      "grammar_check": "Vérification de la grammaire",
      "context": "Contexte",
      "pro_tip": "Conseil Pro : Pour des résultats optimaux, utilisez le formatage Markdown lors de la rédaction."
   },
   {
      "localizations": "German",
      "locale":"de",
      "subscription_group_display_name": "AI-Kredit-Abonnement",
      "app_name": "AI-KontextSchreiber",
      "editor": "Editor",
      "suggestions": "Vorschläge",
      "grammar_check": "Grammatikprüfung",
      "context": "Kontext",
      "pro_tip": "Pro-Tipp: Für die besten Ergebnisse verwenden Sie beim Schreiben die Markdown-Formatierung."
   },{
      "localizations": "Greek",
      "locale":"el",
      "subscription_group_display_name": "Συνδρομή πιστωτικής κάρτας AI",
      "app_name": "Συγγραφέας πλαισίου AI",
      "editor": "Επεξεργαστής",
      "suggestions": "Προτάσεις",
      "grammar_check": "Έλεγχος γραμματικής",
      "context": "Πλαίσιο",
      "pro_tip": "Pro συμβουλή: Για καλύτερα αποτελέσματα, χρησιμοποιήστε τη μορφοποίηση Markdown κατά την εγγραφή."
   },
   {
      "localizations": "Hebrew",
      "locale":"he",
      "subscription_group_display_name": "מנוי זיכוי AI",
      "app_name": "כותב הקשר AI",
      "editor": "עורך",
      "suggestions": "הצעות",
      "grammar_check": "בדיקת דקדוק",
      "context": "הקשר",
      "pro_tip": "טיפ מקצועי: כדי לקבל תוצאות מצויינות, השתמש בעיצוב Markdown בעת כתיבה."
   },
   {
      "localizations": "Hindi",
      "locale":"hi",
      "subscription_group_display_name": "AI क्रेडिट सदस्यता",
      "app_name": "AI संदर्भ लेखक",
      "editor": "संपादक",
      "suggestions": "सुझाव",
      "grammar_check": "व्याकरण जाँच",
      "context": "संदर्भ",
      "pro_tip": "प्रो-टिप: सबसे अच्छे परिणाम के लिए, लेखन करते समय मार्कडाउन फॉर्मेटिंग का उपयोग करें।"
   },
   {
      "localizations": "Hungarian",
      "locale":"hu",
      "subscription_group_display_name": "AI hitel előfizetés",
      "app_name": "AI kontextus író",
      "editor": "Szerkesztő",
      "suggestions": "Javaslatok",
      "grammar_check": "Nyelvtan ellenőrzés",
      "context": "Kontextus",
      "pro_tip": "Pro tipp: A legjobb eredmények érdekében használja a Markdown formázást írás közben."
   }, {
      "localizations": "Italian",
      "locale":"it",
      "subscription_group_display_name": "Sottoscrizione di crediti IA",
      "app_name": "Scrittore di contesto IA",
      "editor": "Editore",
      "suggestions": "Suggerimenti",
      "grammar_check": "Controllo grammaticale",
      "context": "Contesto",
      "pro_tip": "Pro-Tip: Per ottenere i migliori risultati, utilizzare la formattazione Markdown durante la scrittura."
   },
   {
      "localizations": "Japanese",
      "locale":"ja",
      "subscription_group_display_name": "AI クレジットサブスクリプション",
      "app_name": "AI コンテキストライター",
      "editor": "エディター",
      "suggestions": "提案",
      "grammar_check": "文法チェック",
      "context": "文脈",
      "pro_tip": "プロ・ティップ：最適な結果を得るためには、書き込み時にマークダウン形式を使用してください。"
   },
   {
      "localizations": "Korean",
      "locale":"ko",
      "subscription_group_display_name": "AI 신용 카드 구독",
      "app_name": "AI 컨텍스트 작가",
      "editor": "편집기",
      "suggestions": "제안",
      "grammar_check": "문법 검사",
      "context": "문맥",
      "pro_tip": "프로 팁: 최상의 결과를 얻으려면 쓸 때 Markdown 서식을 사용하세요."
   }, {
      "localizations": "Norwegian",
      "locale":"no",
      "subscription_group_display_name": "AI-kredittabonnemang",
      "app_name": "AI-samtalsförfattare",
      "editor": "Redaktør",
      "suggestions": "Forslag",
      "grammar_check": "Grammatikkontroll",
      "context": "Sammenheng",
      "pro_tip": "Pro-tips: For å få best resultat, bruk Markdown-formatering når du skriver."
   },
   {
      "localizations": "Polish",
      "locale":"pl",
      "subscription_group_display_name": "Subskrypcja kredytów AI",
      "app_name": "Pisarz kontekstu AI",
      "editor": "Edytor",
      "suggestions": "Sugestie",
      "grammar_check": "Sprawdzanie gramatyki",
      "context": "Kontekst",
      "pro_tip": "Pro tip: Aby uzyskać najlepsze rezultaty, użyj formatowania Markdown podczas pisania."
   },
   {
      "localizations": "Portuguese (Brazil)",
      "locale":"pt-BR",
      "subscription_group_display_name": "Assinatura de Créditos IA",
      "app_name": "Escritor de Contexto IA",
      "editor": "Editor",
      "suggestions": "Sugestões",
      "grammar_check": "Verificação gramatical",
      "context": "Contexto",
      "pro_tip": "Dica pro: Para melhores resultados, use formatação Markdown ao escrever."
   },
   {
      "localizations": "Portuguese (Portugal)",
      "locale":"pt-PT",
      "subscription_group_display_name": "Assinatura de Créditos de IA",
      "app_name": "Escritor de Contexto de IA",
      "editor": "Editor",
      "suggestions": "Sugestões",
      "grammar_check": "Verificação gramatical",
      "context": "Contexto",
      "pro_tip": "Dica pro: Para melhores resultados, use formatação Markdown ao escrever."
   },   {
      "localizations": "Romanian",
      "locale":"ro",
      "subscription_group_display_name": "Abonament credit IA",
      "app_name": "Scriitor de context IA",
      "editor": "Editor",
      "suggestions": "Sugestii",
      "grammar_check": "Verificare gramaticală",
      "context": "Context",
      "pro_tip": "Sfat profesional: Pentru rezultate optime, utilizați formatarea Markdown atunci când scrieți."
   },
   {
      "localizations": "Russian",
      "locale":"ru",
      "subscription_group_display_name": "Подписка на кредиты ИИ",
      "app_name": "Писатель контекста ИИ",
      "editor": "Редактор",
      "suggestions": "Предложения",
      "grammar_check": "Проверка грамматики",
      "context": "Контекст",
      "pro_tip": "Pro-Tip: Для лучших результатов используйте форматирование Markdown при написании."
   },
   {
      "localizations": "Slovak",
      "locale":"sk",
      "subscription_group_display_name": "AI kreditné predplatné",
      "app_name": "AI kontextový spisovateľ",
      "editor": "Editor",
      "suggestions": "Návrhy",
      "grammar_check": "Kontrola gramatiky",
      "context": "Kontext",
      "pro_tip": "Pro tip: Pre najlepšie výsledky používajte formátovanie Markdown pri písaní."
   },   {
      "localizations": "Spanish (Mexico)",
      "locale":"es-MX",
      "subscription_group_display_name": "Suscripción de Créditos de IA",
      "app_name": "Escritor de Contexto IA",
      "editor": "Editor",
      "suggestions": "Sugerencias",
      "grammar_check": "Revisión gramatical",
      "context": "Contexto",
      "pro_tip": "Consejo pro: Para obtener mejores resultados, utilice el formato Markdown al escribir."
   },
   {
      "localizations": "Spanish (Spain)",
      "locale":"es-ES",
      "subscription_group_display_name": "Suscripción de Créditos IA",
      "app_name": "Escritor de Contexto IA",
      "editor": "Editor",
      "suggestions": "Sugerencias",
      "grammar_check": "Revisión gramatical",
      "context": "Contexto",
      "pro_tip": "Consejo pro: Para obtener mejores resultados, utilice el formato Markdown al escribir."
   },
   {
      "localizations": "Swedish",
      "locale":"sv",
      "subscription_group_display_name": "AI-kredittabonnemang",
      "app_name": "AI-samtalsförfattare",
      "editor": "Redaktör",
      "suggestions": "Förslag",
      "grammar_check": "Grammatikkontroll",
      "context": "Sammanhang",
      "pro_tip": "Pro-tips: För bästa resultat, använd Markdown-formatering när du skriver."
   },
   {
      "localizations": "Thai",
      "locale":"th",
      "subscription_group_display_name": "การสมัครสมาชิกเครดิต AI",
      "app_name": "เขียนแบบสื่อสาร AI",
      "editor": "แก้ไข",
      "suggestions": "ข้อเสนอแนะ",
      "grammar_check": "ตรวจสอบการเขียนภาษา",
      "context": "บริบท",
      "pro_tip": "เคล็ดลับคู่ประโยชน์: ในการเขียนเพื่อผลลัพธ์ที่ดีที่สุด ใช้รูปแบบการเขียน Markdown"
   },
   {
      "localizations": "Turkish",
      "locale":"tr",
      "subscription_group_display_name": "AI Kredi Aboneliği",
      "app_name": "AI Bağlam Yazarları",
      "editor": "Editör",
      "suggestions": "Öneriler",
      "grammar_check": "Dilbilgisi Kontrolü",
      "context": "Bağlam",
      "pro_tip": "Uzman İpucu: En iyi sonuçlar için yazarken Markdown biçimlendirmesini kullanın."
   },   {
      "localizations": "Ukrainian",
      "locale":"uk",
      "subscription_group_display_name": "Підписка на кредити AI",
      "app_name": "Письменник контексту AI",
      "editor": "Редактор",
      "suggestions": "Пропозиції",
      "grammar_check": "Перевірка граматики",
      "context": "Контекст",
      "pro_tip": "Порада експерта: Для отримання найкращих результатів використовуйте форматування Markdown при написанні."
   },
   {
      "localizations": "Vietnamese",
      "locale":"vi",
      "subscription_group_display_name": "Đăng ký tín dụng IA",
      "app_name": "Viết văn bản ngữ cảnh IA",
      "editor": "Biên tập",
      "suggestions": "Đề xuất",
      "grammar_check": "Kiểm tra ngữ pháp",
      "context": "Ngữ cảnh",
      "pro_tip": "Mẹo chuyên gia: Để có kết quả tốt nhất, hãy sử dụng định dạng Markdown khi viết."
   },
   {
      "localizations": "Chinese (Simplified)",
      "locale": "zh-CN",
      "subscription_group_display_name": "AI 信用卡订阅",
      "app_name": "AI 上下文作家",
      "editor": "编辑器",
      "suggestions": "建议",
      "grammar_check": "语法检查",
      "context": "语境",
      "pro_tip": "专家建议：为了得到最佳结果，在写作时使用 Markdown 格式。"
   },{
      "localizations": "Chinese (Traditional)",
      "locale":"zh-TW",
      "subscription_group_display_name": "AI 信用卡訂閱",
      "app_name": "AI 上下文作家",
      "editor": "編輯器",
      "suggestions": "建議",
      "grammar_check": "語法檢查",
      "context": "語境",
      "pro_tip": "專家建議：為了得到最佳結果，在寫作時使用 Markdown 格式。"
   },
   {
      "localizations": "Arabic",
      "locale":"ar",
      "subscription_group_display_name": "اشتراك الائتمان AI",
      "app_name": "كاتب السياق AI",
      "editor": "محرر",
      "suggestions": "اقتراحات",
      "grammar_check": "التدقيق النحوي",
      "context": "السياق",
      "pro_tip": "نصيحة متقدمة: للحصول على النتائج الأفضل، استخدم تنسيق ماركداون عند الكتابة."
   },
   {
      "localizations": "Catalan",
      "locale":"ca",
      "subscription_group_display_name": "Suscripció de crèdit IA",
      "app_name": "Escriptor de context IA",
      "editor": "Editor",
      "suggestions": "Suggeriments",
      "grammar_check": "Comprovació de la gramàtica",
      "context": "Context",
      "pro_tip": "Consell pro: per obtenir els millors resultats, utilitza el format Markdown en escriure."
   },
   {
      "localizations": "Australian English",
      "locale":"en-AU",
      "subscription_group_display_name": "AI Credit Subscription",
      "app_name": "Jot It",
      "editor": "Editor",
      "suggestions": "Suggestions",
      "grammar_check": "Grammar Check",
      "context": "Context",
      "pro_tip": "Pro-Tip: For the best results, use Markdown formatting when writing."
   },
   {
      "localizations": "Malay",
      "locale":"ms",
      "subscription_group_display_name": "Langganan Kredit AI",
      "app_name": "Penulis Kontek AI",
      "editor": "Editor",
      "suggestions": "Cadangan",
      "grammar_check": "Semakan tatabahasa",
      "context": "Konteks",
      "pro_tip": "Pro-Tip: Untuk mendapatkan hasil terbaik, gunakan pemformatan Markdown semasa menulis."
   },
   {
      "localizations": "Indonesian",
      "locale":"id",
      "subscription_group_display_name": "Langganan Kredit AI",
      "app_name": "Penulis Kontekstual AI",
      "editor": "Editor",
      "suggestions": "Saran",
      "grammar_check": "Pemeriksaan tata bahasa",
      "context": "Konteks",
      "pro_tip": "Pro Tip: Untuk hasil terbaik, gunakan pemformatan Markdown saat menulis."
   },
   {
      "localizations": "English",
      "locale":"en-US",
      "subscription_group_display_name": "AI Credit Subscription",
      "app_name": "Jot It",
      "editor": "Editor",
      "suggestions": "Suggestions",
      "grammar_check": "Grammar Check",
      "context": "Context",
      "pro_tip": "Pro-Tip: For the best results, use Markdown formatting when writing."
   },
   {
      "localizations": "English (Canada)",
      "locale":"en-CA",
      "subscription_group_display_name": "AI Credit Subscription",
      "app_name": "Jot It",
      "editor": "Editor",
      "suggestions": "Suggestions",
      "grammar_check": "Grammar Check",
      "context": "Context",
      "pro_tip": "Pro-Tip: For the best results, use Markdown formatting when writing."
   },
   {
      "localizations": "English (United Kingdom)",
      "locale":"en-GB",
      "subscription_group_display_name": "AI Credit Subscription",
      "app_name": "Jot It",
      "editor": "Editor",
      "suggestions": "Suggestions",
      "grammar_check": "Grammar Check",
      "context": "Context",
      "pro_tip": "Pro-Tip: For the best results, use Markdown formatting when writing."
   },
   {
      "localizations": "English (New Zealand)",
      "locale":"en-NZ",
      "subscription_group_display_name": "AI Credit Subscription",
      "app_name": "Jot It",
      "editor": "Editor",
      "suggestions": "Suggestions",
      "grammar_check": "Grammar Check",
      "context": "Context",
      "pro_tip": "Pro-Tip: For the best results, use Markdown formatting when writing."
   }
];
export function getCurrentLocale() {
   const currentNavigatorLanguage = window.navigator.userLanguage || window.navigator.language;
   const exactMatch = LOCALES.find(({locale}) => locale === currentNavigatorLanguage);
   if (exactMatch) {
      return exactMatch;
   }
   
   const languageWithoutRegion = currentNavigatorLanguage.split('-')[0];
   const matchWithoutRegion = LOCALES.find(({locale}) => locale.startsWith(languageWithoutRegion));
   if (matchWithoutRegion) {
      return matchWithoutRegion;
   }
   
   return LOCALES.find(({locale}) => locale === 'en-US');
}
