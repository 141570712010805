export const ENVIRONMENT = [
  ['development', location.origin.includes('localhost')],
  ['production', !location.origin.includes('localhost')]
].find(([name, isActive]) => isActive)[0]

export const getSubdomainClientHost = (subdomain) =>
  ({
    development: `http://${subdomain}.localhost:3012`,
    staging: `https://${subdomain}.staging.supportgpt.co`,
    production: `https://${subdomain}.supportgpt.co`
  }[ENVIRONMENT])


export const getClientHost = () =>
  ({
    development: `http://localhost:3012`,
    staging: `https://staging.supportgpt.co`,
    production: `https://supportgpt.co`
  }[ENVIRONMENT])

export const API_HOST = {
  development: 'http://localhost:5005/v1',
  //development: 'https://api.supportgpt.co/v1',
  staging: 'https://staging.api.supportgpt.co/v1',
  production: 'https://api.supportgpt.co/v1'
}[ENVIRONMENT]

export const WS_API_HOST = {
  //development: 'ws://localhost:5005/api/v1',
  development: 'ws://api.supportgpt.co/v1',
  staging: 'wss://staging.api.supportgpt.co/v1',
  production: 'wss://api.supportgpt.co/v1'
}[ENVIRONMENT]

export const SEND_SOUND = {
  //development: 'http://localhost:3000/sounds/sent.ogg',
  development: 'https://cdn.supportgpt.co/sounds/sent.ogg',
  staging: 'https://cdn.supportgpt.co/sounds/sent.ogg',
  production: 'https://cdn.supportgpt.co/sounds/sent.ogg'
}[ENVIRONMENT]
export const RECEIVED_SOUND = {
   //development: 'http://localhost:3000/sounds/received.ogg',
   development: 'https://cdn.supportgpt.co/sounds/received.ogg',
   staging: 'https://cdn.supportgpt.co/sounds/received.ogg',
   production: 'https://cdn.supportgpt.co/sounds/received.ogg'
}[ENVIRONMENT]

export const SAVE_ENDPOINT = "http://localhost:8080/save_state";
export const LOAD_ENDPOINT = "http://localhost:8080/load_state";
export const AUTOCOMPLETE_ENDPOINT = API_HOST + "/jotit/ai/completion";
export const AI_EDIT_ENDPOINT = API_HOST + "jotit/ai/edit";
export const WAIT_TIME = 1000; // debounce time in milliseconds
