import axios from 'axios';
import getCurrentUser from './getCurrentUser';
import { API_HOST } from '../constants/constants';
export const getOrganization = async (organizationId = undefined, subdomain = undefined) => {
  if(!organizationId){
    return {}
  }

  const apiURL = API_HOST + (organizationId ? '/organizations/' + organizationId : '/organizations');
  const { token, userID } = getCurrentUser();
  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-sb-user-id': userID,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
    return { noOrganization: true }
  }
};
