import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { createConsumer } from '@rails/actioncable';
import {WS_API_HOST} from "../constants/constants";
import {getIPAddress, PlaySound} from "../helpers/helpers";
const useEmbeddableChatWebsocket = (organizationId, contactId, visitorId, chatOpenInternal) => {
   const cableRef = useRef(null);
   const [retryCount, setRetryCount] = useState(0);
   const [isOnline, setIsOnline] = useState(false);
   const [isTyping, setIsTyping ] = useState(false);
   const [isUnread, setIsUnread ] = useState(false);
   const [isMute, setIsMute ] = useState(localStorage.getItem('SGPT_mute') == 'true' || false);
   const [messages, setMessages] = useState([]);
   const [IPAddress, setIPAddress ] = useState([]);
   const ipAddress = useMemo(()=>IPAddress,[IPAddress])
   const toggleMute = useCallback(()=> {
      localStorage.setItem('SGPT_mute', !isMute)
      setIsMute(!isMute)
   },[isMute])
   
   useEffect(() => {
      async function fetchDataAndIP() {
         const ip = await getIPAddress();
         setIPAddress(ip);
         
         // Don't try to connect if we have exceeded the retry count
         if (retryCount >= 3) return;
   
         // If the host is localhost or a specific HTTP URL, do not create a consumer.
         if (window.location.href.includes('localhost') || window.location.href.startsWith('http://')) {
            //console.log('Skipping consumer creation for localhost or HTTP');
            return;
         }
         const cable = createConsumer(`${WS_API_HOST}/embeddable/chat/websocket`);
         const chatChannel = cable.subscriptions.create(
            {
               channel: 'ChatChannel',
               organization_id: organizationId,
               contact_id: contactId,
               visitor_id: visitorId,
            },
            {
               connected() {
                  setIsOnline(true);
               },
               disconnected() {
                  setIsOnline(false);
               },
               received(data) {
                  if (data?.type === 'new_messages') {
                     data.payload.forEach((message) => {
                        setMessages((prevMessages) => [...prevMessages, message]);
                     });
                     
                     if(!chatOpenInternal){
                        setIsUnread(true)
                     }
                  }
                  if (data?.type === 'typing') {
                     setIsTyping(true)
                  }
                  if (data?.type === 'done_typing') {
                     setIsTyping(false)
                  }
               },
            },
         );
         
         cableRef.current = chatChannel;
         
         return () => {
            cable.subscriptions.remove(chatChannel);
            // Increment retry count
            setRetryCount((prevCount) => prevCount + 1);
         };
      }
      
      fetchDataAndIP();
   }, [organizationId, contactId, visitorId, chatOpenInternal, retryCount]);
   
   const getChat = useCallback(() => {
      return new Promise((resolve, reject) => {
         const message = {
            type: 'get_chat',
            payload: {
               organizationId,
               contactId,
               visitorId,
               title: document.title,
               page: window.location.href,
               ipAddress: ipAddress,
               userAgent: navigator.userAgent,
               referrer: document.referrer,
            },
         };
         
         if (cableRef.current) {
            cableRef.current.send(message);
            cableRef.current.received = (data) => {
               //console.log('current received ', data)
               
               if (data?.type === 'new_messages') {
                  let playSound = false
                  data.payload.forEach((message) => {
                     if (message.ai_responded || message.responded_by || message.user_id) {
                        playSound = true;
                     }
                     setMessages((prevMessages) => [...prevMessages, message]);
                  });
                  if (playSound && !isMute) {
                     PlaySound('received');
                  }
               }
               if (data?.type === 'typing') {
                  setIsTyping(true)
               }
               if (data?.type === 'done_typing') {
                  setIsTyping(false)
                  
                  if (!chatOpenInternal) {
                     setIsUnread(true)
                  }
               }
               
               if (data && (!data.type || data?.type === 'chat_history')) {
                  // set the initial messages state to the chat history received from the server
                  //setMessages(data.payload);
                  setMessages((prevMessages) => [...prevMessages, ...data]);
                  //setMessages(data)
               }
               resolve(data);
            };
         } else {
            reject(new Error('Cable connection is not established.'));
         }
      });
   }, [ipAddress, organizationId, contactId, visitorId, chatOpenInternal, isMute]);
   
   const sendChat = (messageObject, callback = ()=>{}) => {
      const message = {
         type: 'new_chat',
         payload: {
            organizationId,
            contactId,
            visitorId,
            ...messageObject,
         },
      };
      
      if (cableRef.current) {
         cableRef.current.send(message);
         if(callback){
            callback()
         }
      }
   };
   
   return {
      getChat,
      isOnline,
      isTyping,
      sendChat,
      messages,
      setMessages,
      ipAddress,
      setIsUnread,
      isUnread,
      toggleMute,
      isMute
   };
};

export default useEmbeddableChatWebsocket;
