import { useQuery } from 'react-query';
import { getEmbeddableChat } from '../api';
import getCurrentUser from "../api/getCurrentUser";
const useEmbeddableChat = (organizationId, contactId, visitorId, isOnline, ipAddress) => {
   const { token, userID } = getCurrentUser();
   
   const result = useQuery(
    'chat_' + organizationId + '_' + contactId + '_' + visitorId+'_' + isOnline+'_' + ipAddress,
    () => getEmbeddableChat(token, userID, organizationId, contactId, visitorId, isOnline, ipAddress),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 10000,
      refetchIntervalInBackground: 15000,
    },
  );
  return result;
};
export default useEmbeddableChat;
