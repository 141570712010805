import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const getEmbeddableChat = async (token, userID, organizationId, contactId, visitorId, isOnline, ipAddress) => {
  if(isOnline){
    return false
  }
  if (!organizationId || [contactId, visitorId, userID].filter(Boolean).length !== 1) {
    return false;
  }
  const title = document.title;
  const page = window.location.href;
  const userAgent = navigator.userAgent;
  const referrer = document.referrer;
  
  const apiURL = `${API_HOST}/embeddable/chat?organizationId=${organizationId || ''}${
     contactId ? `&contactId=${contactId}` : ''
  }${visitorId ? `&visitorId=${visitorId}` : ''}&title=${encodeURIComponent(title)}&page=${encodeURIComponent(page)}&ipAddress=${ipAddress}&userAgent=${encodeURIComponent(userAgent)}&referrer=${encodeURIComponent(referrer)}`;
  
  try {
    const { data } = await axios.get(apiURL, {
      headers: {
        Authorization: 'Bearer ' + token,
        'x-sb-user-id': userID,
      },
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
