export const common = {
   transparent: 'transparent',
   black: '#0b0b0c',
   off_black: '#111111',
   off_black_2: '#0F1418',
   white: '#ffffff',
   off_white: '#EEEEF2',
   light_grey: '#E5E5EA',
   medium_grey: '#9E9EA8',
   dark_grey: '#333333',
   red: '#ff0000',
   imessage: '#1b9feb',
   online: '#44b700',
   increase: '#BEF2D7',
   decrease: '#F9CDBA',
   success: '#45ae57',
   error: '#DA4045',
   warning: '#f8ab3a',
   info: '#536dfe',
   grey: {
      50: '#FAFAFC',
      100: '#F5F5F7',
      200: '#EEEEF2',
      300: '#DEDEE2',
      400: '#BDBDC2',
      500: '#9E9EA8',
      600: '#757575',
      700: '#757575',
      800: '#424247',
      900: '#212126',
   },
};
export const brandingPalette = {
   peach: '#fd6f5c',
   orange: '#fd975c',
   pink: '#fd5c87'
};
export const fontList = [
   '-apple-system',
   'BlinkMacSystemFont',
   '"Segoe UI"',
   'Roboto',
   '"Helvetica Neue"',
   'Arial',
   'sans-serif',
   '"Apple Color Emoji"',
   '"Segoe UI Emoji"',
   '"Segoe UI Symbol"',
].join(',')
